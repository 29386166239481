@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url("https://use.typekit.net/dqd4olk.css");
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500&display=swap');

/* APP Component */

.App {
  text-align: center;
}

.App-loading {
  width: 40px;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.App-body {
  background-color: #000000;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  flex-direction: column;
}

.App-body-loading {
  align-items: center;
  justify-content: center;
}

.App-body h1 {
  font-size: 1.25em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.App-header {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}

.App-header h1 {
  font-size: 2em;
  margin: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

/* EYE Component */

.Eye-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Eye-iris {
  position: absolute;
  width: 70px;
  height: 70px;
  border: 1px solid #FFF;
  background-color: #000;
  border-radius:50%;
  left:40px;  
  top:30px;
}

.Eye-iris:before {
  content:"";
  position: absolute;
  background-color: #000;
  outline: 1px solid #FFF;
  border-radius:50%;
  width:40px;
  height: 40px;
  top:22%;
  left:22%;
}

.Eye-iris:after {
  content:"";
  position: absolute;
  background-color: rgba(255,255,255,0.4);
  border-radius: 50%;
  width:15px;
  height:15px;
  top:35%;
  left:65%;
  box-shadow: -35px 20px rgba(255,255,255,0.4);
}

.Eye-ball {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: transparent;
  border: 1px solid #FFF;
  border-radius:100% 0;
  box-shadow: inset 5px 5px 5px rgba(0,0,0,0.3);
  transform: rotate(45deg);
  top:-10px;
  z-index:1;
  overflow: hidden;
}

.Eye-eyelid {
  position: absolute;
  border-top: 1px solid #FFF;
  border-left: 1px solid #FFF;
  border-radius:100% 0;
  width: 150px;
  height: 150px;
  top:-30px;
  left:3px;
  transform: rotate(45deg);
  transition: .2s;
  z-index:10;
}

.Eye-lid {
  position: absolute;
  z-index:9;
  background-color: #000;
  width: 295px;
  height: 265px;
  border-radius:50%;
  top:-248px;
  left:-68px;
  transition: .2s;
}

.Eye-container:hover .Eye-eyelid {
  transform: rotate(-45deg) rotateX(160deg);
}

.Eye-container:hover .Eye-lid {
  transform: translateY(90px);
}

/* TYPOGRAPHY */

body {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-weight: 300;
  color: white;
}

p {
  font-size: 11pt;
}

h1, h2 {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 200; 
  text-transform: uppercase;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1em;
  margin-bottom: 0em;
}

h3 {
  font-family: 'Josefin Sans', Helvetica, sans-serif;
  font-weight: 400;
}
